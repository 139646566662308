<template>
    <div>
        <!-- <el-card> -->
        <el-form ref="formref" label-width="150px" :model="form" :rules="addFormRulue">
            <el-card>
                <el-form-item label="直播&回放">
                    <el-checkbox v-model="form.broadcastClose" @change="videoLiveStreamingChange">视频直播</el-checkbox>
                    <el-checkbox v-model="thePlayback" @change="playbackChange">视频回放</el-checkbox>
                    <el-checkbox v-model="form.albumsShow" @change="playbackChange">图片直播或回放</el-checkbox>
                </el-form-item>
                <template v-if="form.broadcastClose">
                    <el-form-item ref="address_b3" label="活动直播链接" prop="onlineUrl">
                        <el-col :span="8">
                            <el-input
                                v-model="form.onlineUrl"
                                placeholder="本站线上活动直播地址必须是vhall.com（微吼）"
                            >
                            </el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item ref="address_b3" label="活动直播时间">
                        <el-col :span="8">
                            <div-date-picker
                                :begin.sync="form.broadcastBeginTime"
                                :end.sync="form.broadcastEndTime"
                            ></div-date-picker>
                        </el-col>
                    </el-form-item>
                </template>

                <el-form-item label="视频回放链接" v-if="thePlayback">
                    <el-col :span="8">
                        <el-input v-model="form.broadcastPlaybackUrl" placeholder=""> </el-input>
                    </el-col>
                </el-form-item>

                <template v-if="form.albumsShow">
                    <el-form-item label="活动图集类型" prop="albumsType">
                        <el-col :span="8">
                            <el-select v-model="form.albumsType" placeholder="请选择活动奖励">
                                <el-option label="第三方" :value="1"></el-option>
                                <el-option label="本站" :value="0"></el-option>
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="活动图集链接" prop="bonus">
                        <el-col :span="8">
                            <el-input v-model="form.albumsUrl" placeholder="活动图集链接"> </el-input>
                        </el-col>
                        <!-- <el-checkbox v-model="form.albumsShow" style="margin-left:10px ;">不对外展示</el-checkbox> -->
                        <br />
                        <div class="waring mt">
                            <span v-show="form.albumsType == 0"
                                >请上传图片到COS服务器（详情咨询技术），并复制COS文件夹路径填入这里</span
                            >
                            <span v-show="form.albumsType == 1"
                                >仅支持域名为 https://wechatmini-8.photoplus.cn 的第三方图片直播链接</span
                            >
                        </div>
                    </el-form-item>
                </template>
            </el-card>
            <el-card>
                <el-form-item label="活动类型" prop="acId">
                    <el-select v-model="form.acId" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in huodongList"
                            :key="index"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                    <div class="ml" style="display: inline-block;"></div>
                </el-form-item>
                <el-form-item label="报名渠道" required>
                    <el-select v-model="form.type" placeholder="请选择" @change="resetTheChannel">
                        <el-option label="本站" :value="0" />
                        <el-option label="活动行" :value="1" />
                        <el-option label="第三方活动" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item v-show="form.type == 1" label="活动行报名链接" prop="regUrl">
                    <el-col :span="8">
                        <el-input v-model="form.regUrl" placeholder="例如：活动行链接"> </el-input>
                    </el-col>
                </el-form-item>
                <block v-show="form.type == 2">
                    <el-form-item label="第三方活动链接" prop="thirdpartyUrl">
                        <el-col :span="8">
                            <el-input v-model="form.thirdpartyUrl" placeholder="输入第三方活动链接" />
                        </el-col>
                        <el-checkbox style="margin-left: 10px;" v-model="form.applyLogin">需要登录扬帆账号</el-checkbox>
                    </el-form-item>
                    <el-form-item label="第三方小程序APPID">
                        <el-col :span="4">
                            <el-input v-model="form.miniprogramAppid" placeholder="第三方小程序APPID" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="第三方小程序原始ID">
                        <el-col :span="4">
                            <el-input v-model="form.sourceGhId" placeholder="第三方小程序原始ID" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="第三方小程序地址">
                        <el-col :span="4">
                            <el-input v-model="form.miniprogramUrl" placeholder="第三方小程序页面地址" />
                        </el-col>
                    </el-form-item>
                </block>

                <el-form-item v-if="form.acId == 3" ref="address_b1" label="活动地址" prop="district">
                    <el-col :span="2">
                        <el-select v-model="form.province" placeholder="请选择" @change="ci">
                            <el-option
                                v-for="item in province"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            />
                        </el-select>
                    </el-col>
                    <el-col :span="2" class="ml">
                        <el-select v-model="form.city" placeholder="请选择" @change="cs">
                            <el-option v-for="item in city" :key="item.code" :label="item.name" :value="item.code" />
                        </el-select>
                    </el-col>
                    <el-col :span="2" class="ml">
                        <el-select v-model="form.district" @change="$forceUpdate()">
                            <el-option
                                v-for="item in district"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            />
                        </el-select>
                    </el-col>
                    <el-col :span="7" class="ml" style="margin-left: -20px; padding-right: 30px;">
                        <el-autocomplete
                            v-model="form.detailAddress"
                            style="width: 100%"
                            class="in"
                            placeholder="请输入详细地址"
                            :fetch-suggestions="querySearch"
                            @input="getAddress"
                            @select="mySelect"
                        />
                    </el-col>

                    <el-col :span="2" class="ml">
                        <el-input v-model="form.location.lat" disabled placeholder="坐标x" />
                    </el-col>
                    <el-col :span="2" class="ml">
                        <el-input v-model="form.location.lng" disabled placeholder="坐标y" />
                    </el-col>
                </el-form-item>
                <el-form-item v-if="form.acId == 3" ref="address_b2" label="地图">
                    <Map style="width: 800px;" :addme="form.district" @position="getMsgFormSon" />
                </el-form-item>
            </el-card>
            <el-card>
                <el-form-item label="活动分类" prop="categoryIds">
                    <el-select v-model="form.categoryIds" multiple placeholder="请选择">
                        <el-option
                            v-for="(item, index) in huodong"
                            :key="index"
                            :label="item.name"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="活动标题" prop="title">
                    <el-col :span="8">
                        <el-input v-model="form.title" :maxlength="200" show-word-limit />
                    </el-col>
                </el-form-item>
                <el-form-item label="主办单位" prop="organizers">
                    <el-col :span="8">
                        <el-input v-model="form.organizers" maxlength="100" show-word-limit />
                    </el-col>
                </el-form-item>
                <el-form-item label="举办活动时间" prop="time">
                    <el-col :span="8">
                        <el-date-picker
                            v-model="form.time"
                            type="datetimerange"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%"
                            @change="theRefreshTime"
                        />
                    </el-col>
                </el-form-item>
                <el-form-item label="报名时间" prop="timeSigningUp">
                    <el-col :span="8">
                        <el-date-picker
                            v-model="form.timeSigningUp"
                            type="datetimerange"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%"
                            @change="theRefreshTime"
                        />
                    </el-col>
                </el-form-item>
                <el-form-item label="活动海报" prop="banner">
                    <up-img :action="action" :banner="form.banner" @event="setImg" />
                </el-form-item>
            </el-card>
            <el-card>
                <el-form-item label="限制人数">
                    <el-input-number v-model="form.people" label="请输入数字" :min="0" />
                    <span class="waring ml">0为不限人数</span>
                </el-form-item>

                <el-form-item label="关联活动" prop="joinIds">
                    <fuzzy-selection
                        ref="activityOptions"
                        tipName="活动"
                        sort
                        :type="4"
                        multiple
                        :value.sync="form.joinIds"
                    ></fuzzy-selection>
                </el-form-item>
                <el-form-item label="关联企业" prop="enterpriseIds">
                    <fuzzy-selection
                        ref="enterpriseIds"
                        tipName="企业"
                        sort
                        :type="1"
                        multiple
                        :value.sync="form.enterpriseIds"
                    ></fuzzy-selection>
                </el-form-item>
                <el-form-item label="演讲嘉宾" prop="speakerIds">
                    <fuzzy-selection
                        ref="speakerIds"
                        tipName="嘉宾"
                        :type="7"
                        multiple
                        :value.sync="form.speakerIds"
                    ></fuzzy-selection>
                    <div class="waring mt">
                        请先前往【会员管理/演讲嘉宾】处录入嘉宾信息
                    </div>
                </el-form-item>

                <el-form-item label="奖励积分" prop="bonus">
                    <el-col :span="8"> <el-input v-model="form.bonus" placeholder="填写积分"> </el-input> </el-col
                    ><br />
                    <div class="waring mt">
                        奖励积分建议由运营根据平台生态合理填写。参考比例（人民币：帆币：积分 = 1：1：10）
                    </div>
                </el-form-item>

                <el-form-item label="活动介绍视频" prop="videoUrl">
                    <up-file
                        ref="upVideo"
                        full
                        type="video"
                        tip="上传视频后，活动详情页banner主图会变成播放此视频"
                        :value.sync="form.videoUrl"
                    ></up-file>
                </el-form-item>
                <el-form-item label="自定义分享图">
                    <up-img
                        :action="{ action: 'activityEditor' }"
                        :banner.sync="form.shareBanner"
                        :tip_text="false"
                        theText="上传比例为5:4，如果不传则默认分享为活动主图"
                    ></up-img>
                </el-form-item>
                <el-form-item label="抽奖活动">
                    <fuzzy-selection ref="fuzzy" :value.sync="form.rotaryId" :type="3" clearable tipName="活动">
                    </fuzzy-selection>
                </el-form-item>
                <el-form-item v-show="form.type != 2" label="独立活动页链接">
                    <el-col :span="8">
                        <el-input v-model="form.otherUrl" placeholder="填写后将跳过活动详情页，直接打开活动链接">
                            <!-- <template slot="prepend">http://</template> -->
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item v-show="form.type == 0" label="报名成功自定义文案">
                    <el-col :span="10">
                        <el-input v-model="form.applySuccessMessage" />
                    </el-col>
                </el-form-item>
                <el-form-item label="发送微信服务通知">
                    <el-radio-group v-model="form.notice">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="实名认证">
                    <el-radio-group v-model="form.needAuth">
                        <el-radio :label="1">需要</el-radio>
                        <el-radio :label="0">不需要</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-card>
            <el-card v-show="form.type == 0">
                <el-form-item v-show="form.type == 0" label="邀请函模板">
                    <up-img
                        :action="templates"
                        :banner="form.invitationTemplate"
                        :tip_text="false"
                        :del-img="true"
                        the-text="图片尺寸 1080*1920，.jpg 或 .png格式，不超过 4M"
                        @event="setPosters"
                    />
                </el-form-item>
                <el-form-item v-show="form.type == 0" label="邀请函摸版名字位置" prop="invitationTemplateType">
                    <el-select v-model="form.invitationTemplateType" placeholder="请选择">
                        <el-option label="不显示" :value="2" />
                        <el-option label="居中" :value="1" />
                        <el-option label="靠左" :value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item v-show="form.type == 0" label="邀请函摸版字体颜色" prop="posterColor">
                    <el-color-picker v-model="form.posterColor"></el-color-picker>
                </el-form-item>
            </el-card>
            <el-card v-show="form.type == 0">
                <el-form-item label="团体票">
                    <el-radio-group v-model="form.groupTicket">
                        <el-radio :label="0">关闭</el-radio>
                        <el-radio :label="1">开启</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="票种信息">
                    <el-table :data="form.ticketList" style="width: 100%" border stripe :header-cell-style="rowClass">
                        <el-table-column prop="name" label="票种名称" />
                        <el-table-column prop="type" label="套票设置">
                            <template slot-scope="{ row }">
                                <p>{{ row.type == 0 ? "免费票" : "付费票" }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="价格">
                            <template slot-scope="{ row }">
                                {{ row.price || "免费" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="数量" />
                        <el-table-column prop="needCheck" label="审核设置">
                            <template slot-scope="{ row }">
                                {{ row.needCheck ? "无需审核" : "需要审核" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderNum" label="排序" v-if="id">
                            <template slot-scope="{ row }">
                                <Remark
                                    :row="row"
                                    url="/admin/Activity/ticketOrderNum"
                                    rowKey="orderNum"
                                    @getlist="getTicket"
                                ></Remark>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="{ row, $index }">
                                <el-button
                                    v-show="$store.state.btn_if.indexOf('activity_list_edit') != -1"
                                    type="text"
                                    icon="el-icon-edit"
                                    size="mini"
                                    class="skyblue"
                                    @click="editTicket(row, $index)"
                                    >编辑</el-button
                                >
                                <el-button
                                    v-show="$store.state.btn_if.indexOf('activity_list_delete') != -1"
                                    type="text"
                                    icon="el-icon-delete"
                                    size="mini"
                                    style="color:red"
                                    @click="delTicket(row, $index)"
                                    >删除</el-button
                                >
                                <el-button
                                    v-show="$store.state.btn_if.indexOf('activity_list_delete') != -1"
                                    type="text"
                                    icon="el-icon-s-promotion"
                                    size="mini"
                                    style="color:skyblue"
                                    @click="deactivateTicket(row, $index)"
                                    >{{ row.isSuspend == 0 ? "停用" : "启用" }}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 20px">
                        <el-button @click="newTicket()">新增票种</el-button>
                    </div>
                </el-form-item>
<!--              退票-->
              <el-form-item
                  label="退票比例"
                  prop="refundTicketCharges"
              >
                <el-col :span="2" class="display-flex">
                  <el-input
                      maxLength="3"
                      type="number"
                      max="100"
                      min="0"
                      @input="inputChange"
                      v-model="form.refundTicketCharges"
                      :rows="6"
                  />
                  <span class="margin-l10">%</span>
                </el-col>
              </el-form-item>
                <el-form-item
                    label="退票说明"
                    prop="refundTicketDesc"
                    v-show="form.ticketList && form.ticketList.length"
                >
                    <el-col :span="10">
                        <el-input
                            placeholder="请输入退票说明"
                            v-model="form.refundTicketDesc"
                            type="textarea"
                            :rows="6"
                        />
                    </el-col>
                </el-form-item>
            </el-card>
            <el-card>
                <el-form-item label="活动描述" prop="desc">
                    <el-col :span="10">
                        <el-input v-model="form.desc" maxlength="400" show-word-limit type="textarea" :rows="6" />
                    </el-col>
                </el-form-item>
                <el-form-item label="活动详情" prop="content">
                  <div class="tip" style="color: #fda400;">
                    单张图片大小不能超过1M，分辨率总和不能超过2亿，图片太长请分割多张图片上传！！！
                  </div>
                    <!-- <quill-editor ref="myQuillEditor" v-model="form.content" /> -->
                    <Tiny :value="form.content" @input="chang" />
                </el-form-item>
                <el-form-item v-show="form.type == 0" label="报名表单">
                    <div class="tip" style="color: #fda400;">
                        系统默认已收集用户基本信息，到小程序【我的-编辑资料】中查看具体字段，不要在自定义表单中重复收集！！！
                    </div>
                    <new-form ref="newForm" />
                </el-form-item>
            </el-card>
            <el-card>
                <el-form-item>
                    <el-button v-if="id == null" size="medium" type="primary" style="margin-top: 50px" @click="add">{{
                        btn ? "提交" : "loading.."
                    }}</el-button>
                    <el-button v-if="id" size="medium" type="primary" style="margin-top: 50px" @click="addedit"
                        >修改</el-button
                    >
                    <el-button size="medium" type="warning" style="margin-top: 50px" @click="saveDraft"
                        >保存草稿</el-button
                    >
                </el-form-item>
            </el-card>
        </el-form>
        <!-- </el-card> -->
        <Drawers ref="drawer" :title="title" @getlist="createATicket" @edit="edticket" @getTicket="getTicket" />
    </div>
</template>
<script>
import upImg from "../../components/upImg";
// import tiny from '../../components/Tinymce'
import Map from "../../components/welcome.vue";
import mixins from "@/mixin/list";
// import Drawers from '@/components/Drawer'
import newForm from "@/components/newForm/container.vue";
import fuzzySelection from "@/components/fuzzySelection";

export default {
    components: {
        Map,
        // Tiny: tiny,
        upImg,
        newForm,
        fuzzySelection,
    },
    mixins: [mixins],

    data() {
        var validatePass = (rule, value, callback) => {
            // console.log(value);
            // return
            if (!value.includes("vhall.com")) {
                callback(new Error("本站线上活动直播地址必须是vhall.com（微吼）"));
            } else {
                this.$refs.formref.validateField("checkPass");

                callback();
            }
        };
        var validateUrl = (rule, value, callback) => {
            // console.log(value);
            //   console.log(value.includes('yfchuhai.com'))
            // return
            if (value && !value.includes("yfchuhai.com")) {
                callback(new Error("视频地址仅支持扬帆出海CDN地址，请联系技术上传！"));
            } else {
                // this.$refs.formref.validateField('checkPass')

                callback();
            }
        };
        return {
            queryData: [],
            btn: true,
            checked: true,
            //   quillOption: quillConfig,
            huodongList: [],
            action: {
                action: "activity",
            },
            templates: {
                action: "template",
            },
            list: "",
            channel: "1",
            time: [],
            addFormRulue: {
                title: [{ required: true, message: "请输入活动标题", trigger: "blur" }],
                time: [{ required: true, message: "请输入活动时间", trigger: "blur" }],
                banner: [{ required: true, message: "请上传活动海报", trigger: "blur" }],
                district: [{ required: true, message: "请输入活动地址", trigger: "blur" }],
                desc: [{ required: true, message: "请输入活动描述", trigger: "blur" }],
                regUrl: [{ required: true, message: "请输入活动行报名链接", trigger: "blur" }],
                organizers: [{ required: true, message: "请输入主办单位", trigger: "blur" }],
                acId: [{ required: true, message: "请选择活动分类", trigger: "blur" }],
                ticketList: [{ required: true, message: "请输入票种信息", trigger: "blur" }],
                timeSigningUp: [{ required: true, message: "请选择报名时间", trigger: "blur" }],
                content: [{ required: true, message: "请输入活动详情", trigger: "blur" }],
                onlineUrl: [
                    { required: true, message: "请输入活动直播链接", trigger: "blur" },
                    { validator: validatePass, trigger: "blur" },
                ],
                thirdpartyUrl: [{ required: true, message: "请输入第三方活动链接", trigger: "blur" }],
                categoryIds: [{ required: true, message: "请选择活动分类", trigger: "blur" }],
                videoUrl: [{ validator: validateUrl, trigger: "blur" }],
            },
            // people:0,
            form: {
              // 退票手续费默认为10%
              refundTicketCharges:10,
                title: "",
                type: 0,
                ticketList: [],
                beginTime: "",
                endTime: "",
                thirdpartyUrl: "",
                city: "",
                detailAddress: "",
                organizers: "",
                acId: 3,
                time: [],
                people: 0,
                content: "",
                province: "",
                regUrl: "",
                otherUrl: "",
                orderNum: 0,
                desc: "",
                district: "",
                top: 0,
                recommend: 0,
                location: "",
                initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
                timeSigningUp: [],
                applySuccessMessage: "",
                invitationTemplate: "",
                enterpriseIds: [],
                speakerIds: [],
                joinIds: [],
                invitationTemplateType: 1,
                onlineUrl: "",
                bonus: "0",
                rotaryId: "",
                applyLogin: true,
                categoryIds: [],
                playback: 0,
                notice: 1,
                needAuth: 1,
                albumsType: 1,
                groupTicket: 0,
                albumsShow: false,
                broadcastClose: false,
                // viewsRule: '10,100'
            },
            huodong: [],
            province: [],
            city: [],
            district: [],

            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            id: null,
            header: {
                Token: sessionStorage.getItem("token"),
            },
            options: [],
            options2: [],
            activityOptions: [],
            loading: false,
            title: "添加票种",
            albumsUrl: "",
        };
    },
    computed: {
        lasturl() {
            if (this.form.isOld) {
                return "/yfchuhai.com/dedecms";
            } else {
                return "";
            }
        },
        thePlayback: {
            get() {
                if (this.form.playback == 1) {
                    return true;
                } else {
                    return false;
                }
            },
            set(val) {
                // console.log(val)
                if (val) {
                    this.$set(this.form, "playback", 1);
                } else {
                    this.form.playback = 0;
                }
            },
        },
    },

    created() {
        this.getlist();
        this.getadd();
        this.getp();
        sessionStorage.setItem("action", "activityEditor");
        this.bus.$on("new", () => {
            //   Object.assign(this.$data, this.$options.data.call(this))
            this.form = this.$options.data.call(this).form;
            this.id = null;
        });
    },

    updated() {
        // this.getc()
        // console.log(this.form.time)
        // console.log(this.form.content)
    },
    mounted() {
        // 监听页面滚动事件
        // console.log(this.form.city);
        // 注意：如果由于自己的vue中的样式导致监听不到，可以尝试监听body或者'#app-root'的滚动事件
        // console.log(this.form.city);
    },
    methods: {
      /**
       * 退票比例大于0小于100
       * @param e
       */
      inputChange(e){
        if(e>100){
          this.form.refundTicketCharges=100
        }
        if(e<0){
          this.form.refundTicketCharges=0
        }
        if(e=='00' ){
          this.form.refundTicketCharges=0
        }
      },
        setImg(val) {
            // console.log(val
            this.form.banner = val;
            this.$forceUpdate();
        },
        editTicket(val, i) {
            // console.log(val,i);
            this.$refs.drawer.interfaces = 3;
            this.$refs.drawer.drawer = true;
            this.$refs.drawer.ruleForm = { ...val };
            this.$refs.drawer.editId = i;
            this.title = "编辑票种";
        },

        edticket(val1, val2) {
            this.form.ticketList[val2] = val1;
            this.form.ticketList = [...this.form.ticketList];
            // this.$refs['addFormRulue'].resetFields();
        },
        createATicket(val) {
            // this.ticketList.push
            console.log(val);
            this.form.ticketList.push(val);
            this.$refs["formref"].clearValidate(["ticketList"]);

            // this.ticketList=[...this.ticketList]
        },
        newTicket() {
            this.$refs.drawer.editId = null;
            this.$refs.drawer.interfaces = 3;
            this.$refs.drawer.drawer = true;
            this.$refs.drawer.activityId = this.$route.query.id;
            this.title = "添加票种";
        },
        async delTicket(row, val) {
            if (row.id) {
                var { data: res } = await this.$http.post("/admin/Activity/ticketDel", {
                    id: row.id,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getTicket();
                }
                return;
            }
            this.form.ticketList.splice(val, 1);
            this.form.ticketList = [...this.form.ticketList];
        },
        async deactivateTicket(row, index) {
            //   row.isSuspend = 0 ? (row.isSuspend = 1) : (row.isSuspend = 0)
            var isSuspend = row.isSuspend == 0 ? 1 : 0;
            if (row.id) {
                var { data: res } = await this.$http.post("/admin/Activity/ticketEdit", { ...row, isSuspend });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getTicket();
                }
                return;
            }
            this.$set(this.form.ticketList[index], "isSuspend", isSuspend);
        },
        setPosters(val) {
            this.form.invitationTemplate = val;
            this.$forceUpdate();
        },
        remoteMethod(qu) {
            if (qu != "") {
                this.loading = true;
                this.$http
                    .get(
                        `/admin/Enterprise/getList?page=1&pageSize=50&name=${qu}&isHide=&type=&business=&serverArea=&createDate=&people=&financing=&top=`
                    )
                    .then(({ data: res }) => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            this.options = res.data.list;
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
        remoteMethod2(qu) {
            if (qu != "") {
                this.loading = true;
                this.$http
                    .get(
                        `/admin/User/getList?page=1&pageSize=50&keyword=${qu}&order=&type=&status=&waId=&showCircles=&certType=&business=&ugId=&regDate=`
                    )
                    .then(({ data: res }) => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            this.options2 = res.data.list;
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },

        chang(val) {
            this.form.content = val;
        },
        querySearch(queryString, cb) {
            cb(this.queryData);
            // console.log(queryString);
        },

        async getAddress() {
            const getO = JSON.parse(sessionStorage.getItem("center"));
            // console.log(getO);
            const url = `https://apis.map.qq.com/ws/place/v1/search?boundary=nearby(${getO.lat},${getO.lng},1000)&page_size=5&page_index=1&keyword=${this.form.detailAddress}&key=OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK`;
            const res = await this.$jsonp(url, {
                output: "jsonp",
            });
            console.log(res);
            this.queryData = [];
            // [...res.data, { value: res.data.title }, { address: res.data.address }]
            if (res.data) {
                res.data.forEach((item) => {
                    this.queryData.push({
                        value: item.title,
                        address: item.address,
                        point: item.location,
                    });
                });
                // console.log(this.queryData);
            }
        },

        getMsgFormSon(e) {
            this.form.location = e;
            // .form.location = e.lat + ',' + e.lng
            // console.log(this.form.location.lat)
        },
        theRefreshTime() {
            this.$forceUpdate();
        },
        resetTheChannel() {
            var arr = [
                "regUrl",
                "thirdpartyUrl",
                "otherUrl",
                "applySuccessMessage",
                "invitationTemplate",
                "invitationTemplateType",
            ];
            arr.forEach((i) => {
                this.form[i] = this.$options.data.call(this)[i];
            });
        },
        getlist() {
            // console.log(this.$route.query.id);
            this.id = this.$route.query.id;
            if (this.$route.query.id) {
                this.$http.get("/admin/Activity/getById?id=" + this.$route.query.id).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        // console.log('返回')
                        this.form = {
                            ...res.data,
                            time: [res.data.beginTime, res.data.endTime],
                            location: {
                                lat: res.data.location.split(",")[0],
                                lng: res.data.location.split(",")[1],
                            },
                            timeSigningUp: [res.data.applyBeginTime, res.data.applyEndTime],
                            enterpriseIds: res.data.enterpriseIds && res.data.enterpriseIds.split(","),
                            speakerIds: this.getFillt(res.data.speakerIds),
                            joinIds: res.data.joinIds && res.data.joinIds.split(","),
                            rotaryId: res.data.rotaryId == 0 ? "" : res.data.rotaryId,
                            applyLogin: res.data.applyLogin ? true : false,
                            albumsShow: res.data.albumsShow ? true : false,
                            broadcastClose: res.data.broadcastClose == 0 ? true : false,
                            categoryIds: res.data.categoryIds.split(","),

                            // videoShow: res.data.albumsUrl ? true : false,
                            // initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50
                        };
                        //   console.log(this.form.applyLogin);
                        // if (this.form.regUrl) {
                        //   this.form.type = 1
                        // }
                        // sessionStorage.setItem('city', this.form.city)
                        sessionStorage.setItem("center", JSON.stringify(this.form.location)),
                            this.form.enterpriseIds &&
                                this.form.enterpriseIds.forEach((item, i) => {
                                    if (!item) return;
                                    this.$refs.enterpriseIds.options.push({
                                        id: item,
                                        name: this.form.enterprisesName.split(",")[i],
                                    });
                                });
                        this.form.speakerIds &&
                            this.form.speakerIds.forEach((item, i) => {
                                if (!item) return;
                                this.$refs.speakerIds.options.push({
                                    id: item,
                                    realName: this.form.speakerName.split(",")[i],
                                });
                            });
                        this.form.joinIds &&
                            this.form.joinIds.forEach((item, i) => {
                                if (!item) return;
                                this.$refs.activityOptions.options.push({
                                    id: item,
                                    title: this.form.joinActivityName.split(",")[i],
                                });
                            });
                        this.$refs.fuzzy.options = [
                            {
                                name: this.form.rotaryName,
                                id: this.form.rotaryId,
                            },
                        ];
                        if (this.form.forms && this.$refs.newForm) {
                            this.$refs.newForm.configFormData.list = JSON.parse(this.form.forms);
                        }

                        this.getc();
                        this.getqu();
                        // console.log(this.form.city);
                    }
                    this.getTicket();
                });
            }
        },
        videoLiveStreamingChange(e) {
            if (e) {
                this.thePlayback = false;
                this.form.albumsShow = false;
            }
        },
        playbackChange(e) {
            if (e) {
                this.form.broadcastClose = false;
            }
        },
        getFillt(val) {
            return (
                val &&
                val
                    .split(",")
                    .filter(Boolean)
                    .map(Number)
            );
        },
        async getadd() {
            let [{ data }, { data: res }] = await Promise.all([
                this.$http.get("/admin/Common/getConfigDictionaries?config=" + "activity"),
                this.$http.get("/admin/ActivityCategory/getList"),
            ]);
            this.huodongList = data.data.activityCategory;
            this.huodong = res.data.list;
        },
        async getTicket() {
            let { data: res } = await this.$http.get("/admin/Activity/getTicketList", {
                params: {
                    activityId: this.id,
                    page: 1,
                    pageSize: 5000,
                },
            });
            this.form.ticketList = res.data.list;
            this.form = { ...this.form };
            this.form.ticketList = [...this.form.ticketList];
            // console.log(this.form.ticketList);
            if (this.$refs["formref"]) {
                this.$refs["formref"].clearValidate(["ticketList"]);
            }
        },
        handleAvatarSuccess(res, file) {
            if (res.errorCode == 200) {
                console.log(res.data.url);
                this.form.banner = res.data.url;
                this.$forceUpdate();
                console.log(file);
            }
            // this.form.banner = URL.createObjectURL(file.raw)
        },
        processForm() {
            return {
                ...this.form,
                regUrl: this.form.regUrl,
                otherUrl: this.form.otherUrl,
                beginTime: this.form.time[0],
                endTime: this.form.time[1],
                location: this.form.location.lat ? this.form.location.lat + "," + this.form.location.lng : "",
                applyBeginTime: this.form.timeSigningUp && this.form.timeSigningUp[0],
                applyEndTime: this.form.timeSigningUp && this.form.timeSigningUp[1],
                ticketJson: JSON.stringify(this.form.ticketList),
                forms: JSON.stringify(this.$refs.newForm.configFormData.list),
                enterpriseIds: this.form.enterpriseIds.toString(),
                speakerIds: this.form.speakerIds.toString(),
                joinIds: this.form.joinIds.toString(),
                applyLogin: this.form.applyLogin ? 1 : 0,
                categoryIds: this.form.categoryIds.toString(),
                albumsShow: this.form.albumsShow ? 1 : 0,
                broadcastClose: this.form.broadcastClose ? 0 : 1,
                editStatus: 0,
            };
        },
        add() {
            const form = this.processForm();
            if (this.btn) {
                this.$refs.formref.validate((val) => {
                    if (this.form.type == 1 && !this.form.regUrl) {
                        this.$message.error("请输入活动行报名链接");
                        return;
                    }
                    // if (this.form.type == 0 && this.form.ticketList.length < 1) {
                    //   this.$message.error('至少得输入一个票种')
                    //   return
                    // }
                    this.$http.post("/admin/Activity/add", form).then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            this.$message.success(res.message);
                            this.$router.push("/activity_list");
                        }
                        this.btn = true;
                    });
                });
                this.btn = true;
            }
            // this.btn = false
        },
        getp() {
            this.$http.get("/admin/Area/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.province = res.data;
                }
            });
        },
        mySelect(e) {
            // console.log(e);
            this.form.location = e.point;
            this.bus.$emit("pushaddress", e.point);
        },
        getc() {
            if (this.form.province) {
                this.$http.get("/admin/Area/getList?code=" + this.form.province).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.city = res.data;
                    }
                });
            }
        },
        getqu() {
            if (this.form.city.length != 0) {
                this.$http.get("/admin/Area/getList?code=" + this.form.city).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.district = res.data;
                    }
                });
            }
        },
        ci() {
            this.getc();
            this.form.city = "";
            this.form.district = "";
        },
        cs() {
            this.bus.$emit("getmap", this.form.city);
            this.$forceUpdate();
            this.getqu();
            this.form.district = "";
        },
        addedit() {
            const form = this.processForm();

            this.$refs.formref.validate(() => {
                if (this.form.type == 1 && !this.form.regUrl) {
                    this.$message.error("请输入活动行报名链接");

                    return;
                }
                // if (this.form.type == 0 && this.form.ticketList.length < 1) {
                //   this.$message.error('至少得输入一个票种')
                //   return
                // }
                this.$http.post("/admin/Activity/edit", form).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.$router.push("/activity_list");
                    }
                });
            });
        },
        async saveDraft() {
            // this.addFormRulue = {};
            const form = this.processForm();
            // this.$refs.formref.validate((val) => {});
            let { data: res } = await this.$http.post(!this.id ? "/admin/Activity/add" : "/admin/Activity/edit", {
                ...form,
                editStatus: 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.replace("/activity_list");
            }
        },
    },
};
</script>

<style lang="less" scoped>
.margin-l10{
  margin-left: 10px;
}
.display-flex{
  display: flex;
}
.left {
    border-left: none;
}
.in {
    margin-left: 30px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: auto;
    height: 178px;
    display: block;
}
.biaodan {
    margin-top: 50px;
    .left {
        float: left;
        width: 60%;
        span {
            display: inline-block;
            width: 80px;
            margin-right: 4px;
        }
    }

    .right {
        display: flex;
        flex-wrap: wrap;
        width: 40%;
        .el-tag {
            width: 97px;
            margin-right: 5px;
            text-align: center;
        }
    }
}
.waring {
    color: #fda400;
    line-height: 24px;
    font-size: 12px;
}
.ml {
    margin-left: 10px;
}
.mt {
    margin-top: 10px;
}
</style>
<style>
.el-message.el-message--error {
    z-index: 20000 !important;
}
.el-select-dropdown.el-popper {
    z-index: 20000 !important;
}
.el-card {
    margin-bottom: 10px;
}
</style>
